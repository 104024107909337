import React from 'react'

export const defaultTheme = {
  breakpoints: {
    sm: '700px',
    md: '992px',
    lg: '1200px',
    xl: '1400px',
  },
  colors: {
    primary: '#330d69',
    secondary: '#ff9900',
    secondaryAccent: '#ff6600',
    lightGray: '#e0e0e0',
    mediumGray: '#a7a7a7',
    darkGray: '#444',
    highScore: 'green',
    mediumScore: 'yellow',
    lowScore: 'red',
    disabled: '#e0e0e0',
  },
}

const ThemeContext = React.createContext(defaultTheme)

export default ThemeContext
