import React, { useCallback, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LogoImage from '../../Assets/Images/logo.png'
import { selectSearchText, setSearchText } from '../../Redux/QuerySlice'
import { resultsRequest } from '../../Redux/ResultsSlice'
import { Container, Content, Paragraph, Anchor } from '../../Components/Styled'
import SearchInput from '../../Components/SearchInput/SearchInput'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import ThemeContext from '../../Theme'
import config from '../../Config'

const Logo = styled.img`
  display: block;
  margin: auto;
  max-width: 90%;
  width: 500px;
`

const Slogan = styled(Paragraph)`
  font-size: 2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
`

const PoweredBy = styled.div`
  color: ${({ theme }) => theme.colors.mediumGray};
  margin-top: 2rem;
  text-align: center;
`

const HomeView = props => {
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch()
  const searchText = useSelector(selectSearchText)

  const handleTextChange = useCallback(
    event => {
      dispatch(setSearchText(event.target.value))
    },
    [dispatch],
  )

  const handleSubmit = useCallback(() => {
    dispatch(resultsRequest(searchText))
    dispatch(
      push(config.urls.results + '?q=' + encodeURIComponent(searchText)),
    )
    return false
  }, [searchText])

  return (
    <Container>
      <Content>
        <Logo src={LogoImage} />
        <Slogan>Your FAQ with super-powers!</Slogan>
        <Paragraph color={theme.colors.primary} center>
          For a guide and recipes on how to configure / customize this project,
          <br /> check out the{' '}
          <Anchor href='//google.com' target='blank'>
            superfaq-cli documentation
          </Anchor>
        </Paragraph>
        <form onSubmit={handleSubmit}>
          <Paragraph center>
            <SearchInput
              autoFocus
              value={searchText}
              placeholder='Insert your question...'
              width='400px'
              onChange={handleTextChange}
              handleAction={handleSubmit}
            />
          </Paragraph>
        </form>
        <PoweredBy>
          Powered by <Anchor href='https://github.com/nlpodyssey/spago' target='_blank'>spaGO</Anchor>
        </PoweredBy>
      </Content>
    </Container>
  )
}

export default HomeView
