import styled from 'styled-components'

export const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6rem;
  margin-bottom: 2rem;
  margin-top: 0;

  ${({ color }) => (color ? `color: ${color};` : '')}
  ${({ center }) => (center ? 'text-align: center;' : '')}
`

export const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
`

export const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 1rem;
  display: block;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  width: 100%;
  max-width: 100%;

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.secondary};
  }
`

export const Button = styled.a`
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.disabled : theme.colors.secondary};
  border-radius: 1rem;
  color: #fff;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  display: inline-block;
  padding: 0.5rem 1rem;
  transition: background-color 0.2s linear;

  &:hover {
    background-color: ${({ disabled, theme }) =>
      disabled ? theme.colors.disabled : theme.colors.secondaryAccent};
  }
`

export const Container = styled.div`
  margin: auto;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: ${({ fluid }) => (fluid ? '100%;' : '800px')};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: ${({ fluid }) => (fluid ? '100%;' : '1000px')};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: ${({ fluid }) => (fluid ? '100%;' : '1200px')};
  }
`

export const Grid = styled.div`
  display: flex;
  flex-direction: column;

  ${({ stackable, theme }) => {
    if (stackable) {
      return `
        > div {
          flex-direction: column;

          @media (min-width: ${theme.breakpoints[stackable]}) {
            flex-direction: row;
          }
        }
      `
    } else {
      return ''
    }
  }}
`

Grid.Row = styled.div`
  display: flex;
  flex-direction: row;
`

Grid.Column = styled.div`
  display: flex;
  flex-direction: column;

  ${({ vCenter }) => vCenter ? 'justify-content: center;' : ''}
  ${({ hCenter }) => hCenter ? 'align-items: center;' : ''}
  ${({ width }) => width ? `max-width: ${width};` : ''}
  ${({ width }) => width ? `min-width: ${width};` : ''}
`

export const Content = styled.div`
  padding: 1.5rem;
`
