import React from 'react'
import PropTypes from 'prop-types'

const FlashIcon = ({ color, width }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} viewBox='0 0 78 110'>
    <defs>
      <style>.cls-1{`{fill: ${color}}`}</style>
    </defs>
    <g id='Livello_2' data-name='Livello 2'>
      <g id='Livello_2-2' data-name='Livello 2'>
        <polygon
          className='cls-1'
          points='46.29 0 73.83 0 49.3 36.82 78 36.82 0 110 34.49 52.67 9.72 52.67 46.29 0'
        />
      </g>
    </g>
  </svg>
)

FlashIcon.defaultProps = {
  color: '#000',
  width: '78px',
}

FlashIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
}

export default FlashIcon
