import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import queryReducer from '../Redux/QuerySlice'
import resultsReducer from '../Redux/ResultsSlice'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    query: queryReducer,
    results: resultsReducer,
  })
