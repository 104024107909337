import config from '../Config'

export default {
  results: searchText => fetch(`${config.api.faq}`, {
    method: 'POST',
    body: JSON.stringify({
      question: searchText,
      translate: true,
    }),
  }),
}
