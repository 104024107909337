import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectSearchText, setSearchText } from '../../Redux/QuerySlice'
import {
  resultsRequest,
  selectResults,
  selectFetching,
} from '../../Redux/ResultsSlice'
import LogoImage from '../../Assets/Images/logo.png'
import ResultCard from '../../Components/ResultCard'
import { Container, Grid } from '../../Components/Styled'
import SearchInput from '../../Components/SearchInput/SearchInput'
import Loader from '../../Components/Loader/Loader'
import styled from 'styled-components'
import config from '../../Config'
import { push } from 'connected-react-router'

const Logo = styled.img`
  cursor: pointer;
  display: block;
  max-width: 90%;
  margin: auto;
  width: 300px;
`

const Main = styled.div`
  margin-top: 1.5rem;
  padding: 1.5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 1.5rem 2rem 1.5rem 0;
  }
`

const Form = styled.form`
  align-items: center;
  display: flex;
`

const ResultsView = props => {
  const dispatch = useDispatch()
  const searchText = useSelector(selectSearchText)
  const results = useSelector(selectResults)
  const isFetching = useSelector(selectFetching)
  const urlQuery = useSelector(state => state.router.location.query.q)

  const [active, setActive] = useState(0)

  const fetchData = (question) => {
    console.log('fetching faq')
    dispatch(resultsRequest(question || searchText))
  }

  // alignment of url query and input text
  useEffect(() => {
    if (urlQuery && urlQuery !== searchText) {
      dispatch(setSearchText(decodeURIComponent(urlQuery)))
      fetchData(decodeURIComponent(urlQuery))
    }
  }, [])

  const handleChangeText = useCallback(
    event => {
      dispatch(setSearchText(event.target.value))
    },
    [dispatch],
  )

  const handleSubmit = useCallback((evt) => {
    evt.preventDefault()
    dispatch(
      push(config.urls.results + '?q=' + encodeURIComponent(searchText)),
    )
    fetchData()
  }, [dispatch, searchText])

  return (
    <Container fluid>
      <Grid stackable='md'>
        <Grid.Row>
          <Grid.Column hCenter vCenter>
            <Logo src={LogoImage} onClick={() => dispatch(push(config.urls.home))} />
          </Grid.Column>
          <Grid.Column vCenter hCenter>
            <Form onSubmit={handleSubmit}>
              <SearchInput value={searchText} onChange={handleChangeText} handleAction={handleSubmit} width='400px' />
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Main>
        <Grid stackable='md'>
          <Grid.Row>
            <Grid.Column width='280px' />
            <Grid.Column>
              {isFetching && <Loader />}
              {!isFetching && results &&
                results.map((res, idx) => (
                  <ResultCard
                    active={active === idx}
                    handleSetActive={() => setActive(idx)}
                    id={idx}
                    key={idx}
                    {...res}
                  />
                ))}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Main>
    </Container>
  )
}

export default ResultsView
