import { call, put } from 'redux-saga/effects'
import { resultsSuccess, resultsError } from '../Redux/ResultsSlice'
import { toast } from 'react-toastify'

// attempts to login
export function * fetchResults (api, { payload }) {
  // request
  try {
    const response = yield call(api.results, payload)
    // success?
    if (response.ok) {
      const data = yield call(() => response.json())
      yield put(resultsSuccess(data.faqs))
    } else {
      toast('Fetch results error', { type: 'error' })
      yield put(resultsError())
    }
  } catch (e) {
    toast(`Fetch results error: ${e}`, { type: 'error' })
    yield put(resultsError())
  }
}
