import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, Paragraph } from '../../Components/Styled'
import Score from '../Score'

const Card = styled.div`
  margin: 1rem 0;
  min-width: 100%;
`

const Header = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-size: 1.4rem;
  margin-bottom: .5rem;
`

const Body = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1rem;
`

const ScoreColumn = styled(Grid.Column)`
  margin-bottom: 1rem;
`

const ScoreText = styled.div`
  color: ${({ theme }) => theme.colors.mediumGray};
  font-size: .8rem;
  margin-bottom: .5rem;
`

const ResultCard = props => {
  return (
    <Card>
      <Grid>
        <Grid.Row>
          <ScoreColumn>
            <Score score={props.score} />
          </ScoreColumn>
          <Grid.Column miao='bao'>
            <Header onClick={props.handleSetActive}>{props.question}</Header>
            <ScoreText>Score: {props.score}</ScoreText>
            {props.active && (
              <>
                <Body><Paragraph>{props.answer}</Paragraph></Body>
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

ResultCard.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  active: PropTypes.bool,
  handleSetActive: PropTypes.func,
}

export default ResultCard
