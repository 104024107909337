import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import ThemeContext from '../../Theme'
import { Input } from '../Styled'
import FlashIcon from '../FlashIcon/FlashIcon'
import styled from 'styled-components'

const InputContainer = styled.span`
  border-radius: 1rem;
  display: inline-block;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 100%;

  @media screen and (min-width: ${({ width }) => width}) {
    width: ${({ width }) => width || 'auto'};
  }
`

const Icon = styled.span`
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 0.5rem;

  &:hover {
    opacity: 0.9;
  }
`

const InputField = styled(Input)`
  padding-right: 2rem;
`

const SearchInput = props => {
  const theme = useContext(ThemeContext)
  const [focused, setFocused] = useState(true)
  const [overed, setOvered] = useState(true)

  return (
    <InputContainer width={props.width}>
      <InputField
        {...props}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      <Icon
        onMouseOver={() => setOvered(true)}
        onMouseOut={() => setOvered(false)}
        onClick={props.handleAction}
      >
        <FlashIcon
          color={
            theme.colors[overed ? 'primary' : focused ? 'secondary' : 'mediumGray']
          }
          width='16px'
        />
      </Icon>
    </InputContainer>
  )
}

SearchInput.defaultProps = {
  width: '100%',
  handleAction: () => {},
}

SearchInput.propTypes = {
  width: PropTypes.string,
  handleAction: PropTypes.func,
}

export default SearchInput
