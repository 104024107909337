import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import createRootReducer from './Root'
import { routerMiddleware } from 'connected-react-router'
import rootSaga from '../Sagas'
import history from '../history'

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const initialState = {}
const enhancers = []
const middleware = [sagaMiddleware, routerMiddleware(history)]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.devToolsExtension

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const store = createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers,
)

// then run the saga
sagaMiddleware.run(rootSaga, store.dispatch)

export default store
