import { createSlice } from '@reduxjs/toolkit'

const querySlice = createSlice({
  name: 'query',
  initialState: { text: '' },
  reducers: {
    setSearchText: (state, { payload }) => {
      state.text = payload
    },
  },
})

export const {
  setSearchText,
} = querySlice.actions

console.log(querySlice)

export const selectSearchText = state => state.query.text

export default querySlice.reducer
