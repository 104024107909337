import React from 'react'
import { Route, Switch } from 'react-router' // react-router v4/v5
import { ConnectedRouter } from 'connected-react-router'
import HomeView from './Views/Home'
import ResultsView from './Views/Results'
import history from './history'
import config from './Config'

function App () {
  return (
    <ConnectedRouter history={history}>
      <>
        {/* your usual react-router v4/v5 routing */}
        <Switch>
          <Route exact path='/' render={() => <HomeView />} />
          <Route path={config.urls.results} render={() => <ResultsView />} />
        </Switch>
      </>
    </ConnectedRouter>
  )
}

export default App
