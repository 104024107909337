import { createSlice } from '@reduxjs/toolkit'

const resultsSlice = createSlice({
  name: 'results',
  initialState: { fetching: false, error: false, fetched: false, data: [] },
  reducers: {
    resultsRequest: (state, { payload }) => {
      state.fetching = true
    },
    resultsError: (state, { payload }) => {
      state.data = []
      state.error = true
      state.fetched = true
      state.fetching = false
    },
    resultsSuccess: (state, { payload }) => {
      state.data = payload
      state.error = false
      state.fetched = true
      state.fetching = false
    },
  },
})

export const {
  resultsRequest,
  resultsError,
  resultsSuccess,
} = resultsSlice.actions

export const selectResults = state => state.results.data
export const selectError = state => state.results.error
export const selectFetching = state => state.results.fetching

export default resultsSlice.reducer
