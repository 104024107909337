import { createGlobalStyle } from 'styled-components'
import HelveticaRegular from './Assets/Fonts/Helvetica.ttf'
import HelveticaRegularItalic from './Assets/Fonts/Helvetica-Oblique.ttf'
import HelveticaBold from './Assets/Fonts/Helvetica-Bold.ttf'
import HelveticaBoldItalic from './Assets/Fonts/Helvetica-BoldOblique.ttf'

const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: 'Helvetica';
      src: url(${HelveticaRegular}) format('truetype');
      font-weight: 400;
      font-style: normal;
  }

  @font-face {
      font-family: 'Helvetica';
      src: url(${HelveticaRegularItalic}) format('truetype');
      font-weight: 400;
      font-style: italic;
  }

  @font-face {
      font-family: 'Helvetica';
      src: url(${HelveticaBold}) format('truetype');
      font-weight: 700;
      font-style: normal;
  }

  @font-face {
      font-family: 'Helvetica';
      src: url(${HelveticaBoldItalic}) format('truetype');
      font-weight: 700;
      font-style: italic;
  }

  * {
    box-sizing: border-box;
  }

  #root {
    font-family: 'Helvetica';
    font-weight: 400;
  }

  .fa-spin {
    animation: fa-spin 2s infinite linear;
  }

  @keyframes fa-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`

export default GlobalStyle
