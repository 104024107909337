import React from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import styled from 'styled-components'

const Container = styled.div`
  background: rgba(255, 255, 255, .8);
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: row;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
`

const Loader = props => {
  return (
    <Container>
      <AiOutlineLoading3Quarters className='fa-spin' />
    </Container>
  )
}

export default Loader
