/**
 * What the hell are sagas?
 *
 * TL;DR:
 * Every time you need to trigger some actions as a consequence of another action
 * you can use Sagas to declare such dependencies.
 *
 * It's simpler than you may think.
 * redux-saga uses js ES6 iterators in order to pause and resume
 * tied operations.
 * Why shall we care about tied operations?
 * Side effects baby.
 * The simpler situation to think about is an htt request.
 * es, because it involves an OnRequest event, follwed by an onSuccess
 * or onError event.
 * This events must be treated as redux actions, since everything which
 * changes the store is in fact a redux action.
 * So we have an onRequest action that should immediately fire an
 * onSuccess action => collateral effect
 * redux-saga injects itself as a middleware in redux, inspecting dispatched
 * actions, and can immediately fire other actions if tied to the received one.
 * So in our example, you need only to manually dispatch the request action,
 * and saga will take care of dispatching the subsequent action after the request is complete.
 */
// import { /* takeLatest, takeEvery, takeLeading, */all } from 'redux-saga/effects'
import { takeLatest, all } from 'redux-saga/effects'
import { resultsRequest } from '../Redux/ResultsSlice'
// the majority of sagas regards api calls

/* ------------- Types ------------- */

/* ------------- Sagas ------------- */
import { fetchResults } from './ResultsSagas'

/* ------------- API ------------- */
import api from '../Services/Api.js'

// import API from '../Services/Api'
// const api = API.create()

export default function * root (dispatch) {
  yield all([
    takeLatest(resultsRequest().type, fetchResults, api),
  ])
}

// export this api instance in order to be used elsewhere. This is the unique api instance which
// has the authorization token set
// export { api }
