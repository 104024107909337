import React from 'react'
import PropTypes from 'prop-types'
import ScoreHigh from '../../Assets/Images/score-high.png'
import ScoreMedium from '../../Assets/Images/score-medium.png'
import ScoreLow from '../../Assets/Images/score-low.png'
import styled from 'styled-components'

const Icon = styled.img`
  display: inline-blok;
  margin-right: 1rem;
  width: 40px;
`

const Score = ({ score }) => {
  let icon
  if (score < 0.4) icon = ScoreLow
  else if (score < 0.75) icon = ScoreMedium
  else icon = ScoreHigh

  return (
    <div>
      <Icon src={icon} />
    </div>
  )
}

Score.propTypes = {
  score: PropTypes.number,
}

export default Score
