import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { ToastContainer } from 'react-toastify'
import { Provider } from 'react-redux'
import store from './Redux/Store'
import reportWebVitals from './reportWebVitals'
import ThemeContext, { defaultTheme as theme } from './Theme'
import GlobalStyle from './GlobalStyles'
import { ThemeProvider } from 'styled-components'
import 'react-toastify/dist/ReactToastify.css'

ReactDOM.render(
  <Provider store={store}>
    <ThemeContext.Provider value={theme}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ToastContainer />
        <App />
      </ThemeProvider>
    </ThemeContext.Provider>
  </Provider>,
  document.getElementById('root'),
)

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
